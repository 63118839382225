@import '../../styles/palette';
@import '../../styles/mixins';
@import '../../styles/typography';

.EnterpriseSignUp {
  @include white-container(448px);
  @include flexbox(center, center);
  flex-direction: column;
  margin: 64px auto;
  h4 {
    @include headline-medium;
    margin-bottom: 24px;
  }
  .success-header {
    background-color: #ecf9f6;
    width: 352px;
    padding: 27px;
    @include flexbox;
    border-radius: 4px;
    gap: 10px;
    .check-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      @include flexbox(center, center);
      background-color: $Success60;
      margin-top: 2px;
      .MuiSvgIcon-root {
        fill: #fff;
        padding: 6px;
      }
    }
    p {
      @include body-medium;
      color: $Success50;
      margin: 0;
    }
  }
  .email-field {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 42px;
  }
  .password-field {
    width: 100%;
  }
  .forgot-password-error-link,
  .resend-code-error-link {
    @include button-to-link;
    @include body-small;
    color: $LightError;
    margin-top: -2px;
  }
  .confirm-login-btn {
    @include blue-contained-button;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 12px;
    .loading-spinner {
      filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
      margin-right: 8px;
      height: 16px;
      width: 16px;
      @include spin-infinitely();
    }
  }
}
