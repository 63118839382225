@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/palette';

.ResetPassword {
  @include white-container(448px);
  @include flexbox(center, center);
  flex-direction: column;
  gap: 12px;
  margin: 64px auto;
  h4 {
    @include headline-medium;
    text-align: center;
    margin-bottom: 16px;
  }
  h5 {
    @include body-large;
    margin: 0;
  }
  p {
    @include body-large;
    margin: 0;
    &.p-body-medium {
      @include body-medium;
    }
  }
  .email-field, 
  .password-field {
    width: 100%;
    margin-top: 24px;
  }
  .email-sent-icon {
    width: 26px;
    color: $Success;
  }
  .success-message {
    margin: 24px auto 0;
    background-color: #ecf9f6;
    border-radius: 4px;
    width: 352px;
    height: 72px;
    @include flexbox(center, center);
    gap: 11px;
    .check-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      @include flexbox(center, center);
      background-color: $Success60;
      .MuiSvgIcon-root {
        fill: #fff;
        padding: 6px;
      }
    }
    span {
      @include body-medium;
      color: $Success50;
    }
  }
  .confirm-reset-btn {
    @include blue-contained-button;
    margin-top: 24px;
    width: 100%;
  }
  .back-link,
  .send-new-link,
  .back-to-login,
  .return-to-portal {
    @include text-only-button(underline);
    color: $InitioBlue;
    width: 100%;
  }
  .loading-spinner {
    filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
    margin-right: 8px;
    height: 16px;
    width: 16px;
    @include spin-infinitely();
  }
}
