@import '../styles/mixins';
@import '../styles/typography';
@import '../styles/palette';

.PasswordStrengthBar {
  width: 100%;
  margin-top: 8px;
  .password-req-bar {
    min-width: 100%;
    @include flexbox;
    gap: 4px;
    div {
      flex-grow: 1;
      height: 8px;
      gap: 4px;
      background-color: #d9d9d9;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &.fill {
        &.Weak {
          background-color: $LightError;
        }
        &.Okay {
          background-color: $Error60;
        }
        &.Good {
          background-color: rgb(11 108 64 / 80%);
        }
        &.Great {
          background-color: $Primary60;
        }
      }
    }
  }
  .password-strength-label {
    @include flexbox(flex-end);
    &.hidden {
      visibility: hidden;
    }
    &.Weak {
      color: $LightError;
    }
    &.Okay {
      color: $Error60;
    }
    &.Good {
      color: rgb(11 108 64 / 80%);
    }
    &.Great {
      color: $Primary60;
    }
  }
  .req-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .req-item {
      @include flexbox(flex-start, center);
      gap: 4px;
      .req-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $InitioBlue;
      }
      span {
        @include body-small;
      }
      &.completed {
        .req-bullet {
          background-color: #d9d9d9;
        }
        span {
          color: $LightOutline;
        }
      }
    }
  }
}
