@import '../../styles/palette';
@import '../../styles/mixins';
@import '../../styles/typography';

.VerificationCodeSent {
  @include white-container(448px);
  @include flexbox(center, center);
  flex-direction: column;
  text-align: center;
  margin: 64px auto;
  h4 {
    @include headline-medium;
    margin-top: 12px;
    margin-bottom: 24px;
  }
  p {
    @include body-large;
  }
  .go-to-login {
    @include text-only-button(underline);
    font-size: 16px;
  }
  .MuiSvgIcon-root {
    color: $Success;
  }
}
