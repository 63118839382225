@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/palette';

.Signup {
  .email-verified-error-header {
    margin-top: 42px;
    background: rgb(249 222 220 / 30%);
    width: 352px;
    padding: 24px 52px 24px 28px;
    @include flexbox;
    border-radius: 4px;
    gap: 11px;
    svg {
      flex-shrink: 0;
      margin-top: 2px;
      height: 17px;
      width: 18px;
    }
    p {
      @include body-medium;
      color: $LightError;
      margin: 0;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    margin-top: 42px;
  }
  .resend-code-error-link,
  .forgot-password-error-link {
    @include button-to-link;
    @include body-small;
    color: $LightError;
    margin-top: -1.5px;
    &.in-header {
      font-size: 14px;
      margin-top: -2.75px;
    }
  }

  .continue-button,
  .sign-up-button {
    @include blue-contained-button;
    margin-top: 30px;
    width: 100%;
    .loading-spinner {
      filter: brightness(0) saturate(100%) invert(100%) sepia(8%) saturate(7441%) hue-rotate(207deg) brightness(108%) contrast(110%);
      margin-right: 8px;
      height: 16px;
      width: 16px;
      @include spin-infinitely();
    }
  }

  .login-btn {
    @include text-only-button(underline);
    width: 100%;
    color: $InitioBlue;
    margin: 24px auto 0;
  }

  .back-btn {
    @include text-only-button(underline);
    width: 100%;
    color: $InitioBlue;
    margin: 24px auto 0;
  }

  .user-agreement-and-privacy-text,
  .email-marketing-text {
    @include body-medium;
  }

  .conditions-link {
    color: $InitioBlue;
    text-decoration: none;
    &:hover,
    &:visited {
      color: $InitioBlue;
      opacity: 0.8;
    }
    .MuiSvgIcon-root {
      font-size: 18px;
      margin-bottom: 2px;
    }
  }

  .checkbox {
    @include flexbox;
    .MuiCheckbox-root {
      padding: 0;
      margin-top: 4px;
      margin-right: 12px;
      .unchecked-icon {
        width: 16px;
        height: 16px;
        border: 1px solid #49454f;
        border-radius: 2px;
      }
      .checked-icon {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        background-color: $InitioBlue;
        @include flexbox(center, center);
        .MuiSvgIcon-root {
          color: #fafafd;
          padding: 6px;
        }
      }
    }
    &.email-marketing {
      margin: 42px auto 12px;
    }
    &.user-agreement-and-privacy {
      margin: 0 auto;
    }
  }

  .user-agreement-error {
    @include flexbox(flex-start);
    gap: 5px;
    margin-left: 16px;
    @include body-small;
    color: $LightError;
    .MuiSvgIcon-root {
      margin-top: 2px;
      padding: 0;
      height: 14px;
      width: 14px;
      color: $LightError;
    }
  }
}
