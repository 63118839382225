@import '../../styles/mixins';
@import '../../styles/typography';
@import '../../styles/palette';

.Signup {
  @include white-container(448px);
  @include flexbox(center, center);
  flex-direction: column;
  margin: 64px auto;
  h4 {
    @include headline-medium;
    text-align: center;
    margin-bottom: 18px;
  }

  // Progress bar
  .current-step {
    @include body-medium;
    margin-left: auto;
  }
  .MuiLinearProgress-root {
    width: 100%;
    height: 16px;
    border-radius: 16px;
    outline: 1px solid $InitioBlue;
    background: none;
    .MuiLinearProgress-bar {
      background: linear-gradient(to right, #4295f3 0%, #3d7bc6 100%);
    }
  }
}
