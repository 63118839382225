@import './palette';
@import './typography';

@mixin white-container($containerWidth) {
  background-color: $Container2;
  width: $containerWidth;
  box-shadow: 0 8px 16px rgb(0 49 93 / 20%);
  border-radius: 28px;
  padding: 40px 48px;
  @media only screen and (width <= 450px) {
    width: 350px;
    .MuiInputBase-root {
      width: 280px !important;
    }
  }
}

@mixin flexbox($justifyContent: flex-start, $alignItems: flex-start) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin button-to-link {
  border: none;
  text-transform: none;
  text-decoration: underline;
  background: none !important;
  padding: 0;
  color: $InitioBlue;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  &:active,
  &:hover,
  &:focus {
    opacity: 0.8;
  }
  &:disabled {
    color: inherit;
  }
}

@mixin default-buttons-style {
  svg {
    margin-right: 10px;
  }
  @include label-large;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  &:focus-visible {
    outline: 2px solid $InitioBlue;
  }
  &:active {
    outline: none;
  }
}

@mixin blue-contained-button {
  @include default-buttons-style;
  background-color: $Primary50;
  color: #fff;
  &:hover {
    background-color: $Primary30;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    background-color: $Primary30;
  }
  &:active {
    background-color: $Primary30;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #fff;
    background-color: $Outline;
  }
}

@mixin tonal-contained-button {
  @include default-buttons-style;
  background-color: #d0d9ee;
  color: $BlueSteel;
  &:hover {
    background-color: #c3d4ee;
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    background-color: #bbcbe4;
  }
  &:active {
    background-color: #bac2d5;
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: #303d5c;
    opacity: 0.8;
    background-color: $Outline;
  }
}

@mixin light-filled-button {
  @include default-buttons-style;
  background-color: $LightSurface;
  color: $Primary40;
  box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  &:hover {
    background: rgb(0 72 132 / 8%);
    box-shadow: 0 4px 8px rgb(199 203 220 / 85%);
  }
  &:focus-visible {
    background: rgb(0 72 132 / 12%);
  }
  &:active {
    background: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
    background-color: $Outline;
  }
}

@mixin outlined-button {
  @include default-buttons-style;
  background: none;
  border: 1px solid #315880;
  color: $Primary40;
  &:hover {
    background-color: rgb(0 72 132 / 8%);
  }
  &:focus-visible {
    background-color: rgb(0 72 132 / 8%);
    border: 1px solid $InitioBlue;
    outline: 1px solid $InitioBlue;
  }
  &:active {
    background-color: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
    border: 1px solid $Outline;
  }
}

@mixin text-only-button($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary40;
  @if $textDecoration != none {
    color: $InitioBlue;
  }
  &:hover {
    background-color: rgb(0 72 132 / 8%);
    text-decoration: $textDecoration;
  }
  &:focus-visible {
    background-color: rgb(0 72 132 / 8%);
  }
  &:active {
    background-color: rgb(0 28 57 / 10%);
    box-shadow: inset 0 4px 8px rgb(18 29 33 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
  }
}

@mixin text-only-button-dark($textDecoration: none) {
  @include default-buttons-style;
  text-decoration: $textDecoration;
  background: none;
  border: none;
  color: $Primary80;
  &:hover {
    background-color: rgb(158 202 255 / 8%);
  }
  &:focus-visible {
    background-color: rgb(158 202 255 / 8%);
  }
  &:active {
    background-color: rgb(158 202 255 / 12%);
  }
  &:disabled {
    color: rgb(48 61 92 / 38%);
  }
}

@mixin spin-infinitely($duration: 4000ms) {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation-name: spin;
  animation-duration: $duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
