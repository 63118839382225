@import '../styles/typography.scss';
@import '../styles/palette.scss';
@import '../styles/mixins.scss';

.NavBar {
  @include flexbox(flex-start, center);
  width: 100%;
  height: 56px;
  background: transparent !important;
  padding: 0;
  border-bottom: 1px solid transparent;

  border-image: linear-gradient(
      to right,
      rgba(48, 61, 92, 0.5) 0%,
      #f5f5f5 27.6%,
      #dbe0f0 50.52%,
      rgba(219, 224, 240, 0) 100%
    )
    1;
    .initio-logo {
      padding: 0;
      margin-left: 22px;
      border-radius: 2px;
      height: 48px;
      &:hover {
        background-color: transparent;
      }
      &:focus-visible {
        outline: 2px solid $InitioBlue;
      }
    }
}
