$InitioBlue: #4295f3;

$Primary80: #9ecaff;
$Primary60: #0095f7;
$Primary50: #007bcc;
$Primary40: #0061a4;
$Primary30: #00497d;

$Outline: #ccc;
$Container2: #fafafd;
$LightOnSurfaceVariant: #49454f;
$Error60: #f45c53;

$DarkOutline: #999ba5;
$LightError: #b3261e;

$Success: #00be8a;
$Success50: #008862;
$Success60: #00a577;

$LightOutline: #79747e;